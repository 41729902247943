import React, { useEffect } from "react";
import { Collapse, MenuItem, Select, TextField } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Button from "@material-ui/core/Button";

import { BillingRecord, FrontendData } from "../../../shared/types";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import InternalValueTextField from "../../components/InternalValueTextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterHeader: {
            marginLeft: theme.spacing(1),
        },
        filterDropdown: {
            marginRight: theme.spacing(2),
        },
        filters: {
            marginTop: theme.spacing(1),
        },
    })
);

type FilterField = {
    key: keyof FrontendData | keyof BillingRecord;
    display: string;
};

const filterFields: FilterField[] = [
    { key: "patientId", display: "PHN" },
    { key: "firstName", display: "Doctor Name" },
    { key: "ban", display: "BAN" },
    { key: "batchNum", display: "Batch Number" },
    { key: "billingCode", display: "Billing Code" },
    { key: "uid", display: "Record ID" },
    { key: "claimNum", display: "Claim Number" },
    { key: "comments", display: "Comments" },
    { key: "dxCode", display: "DX Code" },
    { key: "error", display: "Error" },
    { key: "errorCode", display: "Error Code" },
    { key: "status", display: "Status" },
    { key: "subType", display: "Sub-Type" },
    { key: "type", display: "Type" },
    { key: "activityId", display: "Activity ID" },
    { key: "gpUid", display: "GP UID" },
    { key: "specialistUid", display: "Specialist UID" },
];

export interface Filter {
    field: FilterField;
    value: string;
}

export function matchesFilter(record: FrontendData, filters: Filter[]) {
    return filters.every((filter) => {
        let value: any;
        if (filter.field.key === "firstName") {
            value = `${record.data.firstName} ${record.data.lastName}`;
        } else if (filter.field.key === "uid") {
            value = record.uid;
        } else if (
            filter.field.key === "index" ||
            filter.field.key === "data"
        ) {
            return false;
        } else {
            value = record.data[filter.field.key];
        }
        if (value !== undefined && filter.value !== undefined)
            return (
                value
                    .toString()
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
            );
        else return false;
    });
}

interface FiltersProps {
    enableFilter: boolean;
    setEnableFilter: (enableFilter: boolean) => void;
    filters: Filter[];
    setFilters: (filters: Filter[]) => void;
}

const Filters = ({
    enableFilter,
    setEnableFilter,
    filters,
    setFilters,
}: FiltersProps) => {
    const classes = useStyles();

    useEffect(() => {
        if (enableFilter && filters.length === 0) {
            addFilter();
        }
    }, [enableFilter]);

    const handleToggleButtonFilter = () => {
        setEnableFilter(!enableFilter);
    };

    const handleFilterFieldChange = (
        key: keyof FrontendData | keyof BillingRecord,
        index: number
    ) => {
        const field = filterFields.find((f) => f.key === key);
        if (field !== undefined) {
            const newFilters = [...filters];
            newFilters[index].field = field;
            setFilters(newFilters);
        }
    };

    const handleFilterValueChange = (value: string, index: number) => {
        const newFilters = [...filters];
        newFilters[index].value = value;
        setFilters(newFilters);
    };

    const addFilter = () => {
        const newFilters = [...filters];
        newFilters.push({
            field: filterFields[0],
            value: "",
        });
        setFilters(newFilters);
    };

    const removeFilter = (index: number) => {
        const newFilters = [...filters];
        newFilters.splice(index, 1);
        setFilters(newFilters);
    };

    const resetFilters = () => {
        setFilters([]);
    };

    return (
        <>
            <ToggleButton
                value="filter"
                selected={enableFilter}
                onChange={handleToggleButtonFilter}
            >
                <FilterListIcon />
                <Typography
                    className={classes.filterHeader}
                    variant="subtitle1"
                >
                    Filter Results
                </Typography>
            </ToggleButton>

            <Collapse in={enableFilter} timeout="auto">
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Add Filter Field
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={addFilter}>
                            <AddCircleIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={resetFilters}>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.filters}>
                    {filters.map((filter, index) => {
                        return (
                            <React.Fragment
                                key={`filter_${filter.field.key}_${index}`}
                            >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Select
                                            className={classes.filterDropdown}
                                            onChange={(e: any) => {
                                                handleFilterFieldChange(
                                                    e.target.value,
                                                    index
                                                );
                                            }}
                                            value={filter.field.key}
                                        >
                                            {filterFields.map((filterField) => {
                                                return (
                                                    <MenuItem
                                                        key={`filter_${index}_${filterField}`}
                                                        value={filterField.key}
                                                    >
                                                        {filterField.display}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <InternalValueTextField
                                            value={filter.value}
                                            onValueChange={(value: string) => {
                                                handleFilterValueChange(
                                                    value,
                                                    index
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => {
                                                removeFilter(index);
                                            }}
                                        >
                                            <RemoveCircleIcon color="primary" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </div>
            </Collapse>
        </>
    );
};

export default Filters;
