import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FrontendData } from "../../../shared/types";
import { parse } from "json2csv";
import { saveAs } from "file-saver";

interface ExportCSVProps {
    fromDate: Date;
    toDate: Date;
    dataToExport: FrontendData[];
}
const ExportCSV = ({ fromDate, toDate, dataToExport }: ExportCSVProps) => {
    const exportAsCSV = (event: any) => {
        event.preventDefault();
        const csvData = [...dataToExport].map((frontendData: FrontendData) => {
            return Object.assign(frontendData.data, {
                dateTime: new Date(
                    frontendData.data.created.seconds * 1000
                ).toLocaleString(),
                comments:
                    frontendData.data.comments !== undefined
                        ? frontendData.data.comments
                        : "",
                assessedAmt:
                    frontendData.data.assessedAmt !== undefined
                        ? frontendData.data.assessedAmt
                        : 0,
            });
        });
        let csv = parse(csvData, {
            fields: [
                "dateTime",
                "patientId",
                "firstName",
                "lastName",
                "referringPracId",
                "specialistPracId",
                "billingCode",
                "type",
                "subType",
                "status",
                "assessedAmt",
                "error",
                "comments",
            ],
        });

        let fileName = `billing_${fromDate.toDateString()}_${toDate.toDateString()}.csv`;

        // Create a blob of the data
        let fileToSave = new Blob([csv], { type: "text/plain;charset=utf-8" });
        saveAs(fileToSave, fileName);
    };

    return (
        <div>
            <Tooltip title="Export current view (including filters) as a .csv file.">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={exportAsCSV}
                >
                    Export
                </Button>
            </Tooltip>
        </div>
    );
};

export default ExportCSV;
