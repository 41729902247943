import React from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../shared/types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    // Hide the up/down arrows on number fields
    input: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
});

interface FormTextFieldProps extends ControlledInputProps {
    rules?: any;
}

const FormNumberField = ({
    name,
    control,
    defaultValue,
    rules,
    label,
    ...rest
}: FormTextFieldProps) => {
    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={rules}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    {...rest}
                    className={classes.input}
                    type="number"
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                        disableAnimation: true,
                    }}
                    // Prevent changing the number field by scrolling
                    onWheel={() => {
                        if (document.activeElement) {
                            (document.activeElement as HTMLElement).blur();
                        }
                    }}
                    // Prevent changing the number field by hitting up/down arrows
                    onKeyDown={(e) => {
                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                        }
                    }}
                />
            )}
        />
    );
};

export default FormNumberField;
